/*
 * It's Happening.
 * Copyright © 2019 It's Happening.
 */

import React from 'react';
import { Link } from 'gatsby';

const Header = () => (
    <header id="page-header" className="container">
        <Link to="/" className="logo" title="It's Happening" />
    </header>
);

export default Header;

/*
 * It's Happening.
 * Copyright © 2019 It's Happening.
 */

import React from 'react';
import { Link } from 'gatsby';

import rss from '../img/rss.svg';
import twitter from '../img/twitter.svg';

const Footer = () => (
    <footer>
        <section className="pure-g">
            <div className="pure-u-1 pure-u-md-1-2">
                <ul className="menu-list">
                    <li>
                        <Link to="/">Home</Link>
                    </li>
                    <li>
                        <Link to="/about">About</Link>
                    </li>
                    <li>
                        <Link to="/contact">Contact</Link>
                    </li>
                </ul>
            </div>
            <div className="pure-u-1 pure-u-md-1-2 social-links">
                <a href="/rss.xml" className="rss-link">
                    <img src={rss} alt="RSS Feed" />
                </a>
                <a
                    href="https://twitter.com/ItsHappeningIO"
                    className="twitter-link"
                >
                    <img className="fas fa-lg" src={twitter} alt="Twitter" />
                </a>
            </div>
        </section>
    </footer>
);

export default Footer;

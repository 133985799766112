/*
 * It's Happening.
 * Copyright © 2019 It's Happening.
 */

import React from 'react';
import Header from './Header';
import Footer from './Footer';
import '../styles/metropolis.scss';
import '../styles/style.scss';

const TemplateWrapper = ({ children }) => (
    <div className="page-wrapper">
        <div className="page-container">
            <Header />
            {children}
        </div>
        <Footer />
    </div>
)

export default TemplateWrapper;
